(function () {
    // Init
    const container = document.getElementById("js-obaia-mouse-obey-wrapper")
    const mouseObeyedElements = document.querySelectorAll('.js-obaia-mouse-obey')
    // heroTitle = document.getElementById("js-hero-title");

    // Mouse
    const mouse = {
        _x: 0,
        _y: 0,
        x: 0,
        y: 0,
        updatePosition: function (event) {
            var e = event || window.event
            this.x = e.clientX - this._x
            this.y = e.clientY - this._y
        },
        setOrigin: function (e) {
            this._x = e.offsetLeft + Math.floor(e.offsetWidth / 2)
            this._y = e.offsetTop + Math.floor(e.offsetHeight / 2)
        },
        show: function () {
            return "(" + this.x + ", " + this.y + ")"
        }
    };

    // Track the mouse position relative to the center of the container.
    mouse.setOrigin(container);

    //-----------------------------------------

    let counter = 0;
    let updateRate = 2;
    let isTimeToUpdate = function () {
        return counter++ % updateRate === 0;
    };

    //-----------------------------------------

    const onMouseEnterHandler = function (event) {
        update(event)
    };

    const onMouseLeaveHandler = function () {
        // inner.style = ""
    };

    const onMouseMoveHandler = function (event) {
        if (isTimeToUpdate()) {
            update(event)
        }
    };
    //-----------------------------------------
    const update = function (event) {
        mouse.updatePosition(event)
        updateTransformStyle(
            // (mouse.y / 100).toFixed(2),
            // (mouse.x / 100).toFixed(2),
            mouse.x,
            mouse.y,
        )
    }

    const updateTransformStyle = function (x, y) {
        for (let index = 0; index < mouseObeyedElements.length; index++) {
            const element = mouseObeyedElements[index]
            let threshold = element.getAttribute('data-obey-threshold') || 100
            let style = "translateX(" + ( x / threshold ) + "px) translateY(" + ( y / threshold ) + "px) translateZ(-1px)"
            element.style.transform = style
            element.style.webkitTransform = style
            element.style.mozTransform = style
            element.style.msTransform = style
            element.style.oTransform = style
        }
    };
    //-----------------------------------------
    container.onmouseenter = onMouseEnterHandler
    container.onmouseleave = onMouseLeaveHandler
    container.onmousemove = onMouseMoveHandler
})();